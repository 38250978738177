import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'

import { getBase64 } from '../../utils/string'
import {
  deleteEventPlaceImageAPI,
  uploadEventPlaceImageAPI,
} from '../../utils/api'

import ModalHandler from '../../utils/modal'
import ImageCropper from '../image-cropper/index'
import MapSelector from '../map-selector/index'
import Loading from '../loading'
import Input from '../form/input'
import Button from '../form/buttons'

const styleBoxLoad = { position: 'relative', height: '80px' }
const style1 = { position: 'relative' }
const style2 = { display: 'none' }
const style3 = { marginLeft: 20 }
const style4 = { textAlign: 'center', padding: 10 }
const style5 = { padding: 5 }
const styleImageCover = { width: '100%' }

const onClickUpload = () => document.getElementById('input-cover-place').click()
const onUpload = setImage => async e => {
  if (e.currentTarget.files[0]) {
    const data = await getBase64(e.currentTarget.files[0])
    setImage(data)
    ModalHandler.openModal('ModalPlaceCropper')
  }
}

const ModalPlaceCropper = ({ props, image, setIsUpload, setPicture }) => (
  <div
    id="ModalPlaceCropper"
    className="modal"
    style={{ paddingTop: 30, zIndex: 1180 }}
  >
    <div className="modal-content" style={{ maxWidth: 550 }}>
      <ImageCropper
        locale={props.locale}
        image={image}
        onClose={() => ModalHandler.closeModal('ModalPlaceCropper')}
        onSubmit={async imageUrl => {
          ModalHandler.closeModal('ModalPlaceCropper')

          setIsUpload(true)
          await deleteEventPlaceImageAPI(props.data.id)
          const res = await uploadEventPlaceImageAPI(props.data.id, {
            data_url: imageUrl,
          })
          if (res.code === 'OK') {
            setPicture(imageUrl)
            const payload = {
              ...props.data,
            }
            payload.place.picture = imageUrl
            props.onSubmit(payload)
            setIsUpload(false)
          } else {
            await swal(
              props.locale.ERROR,
              props.locale.WARNING_UPLOAD_FAIL,
              'error'
            )
            setIsUpload(false)
          }
        }}
      />
    </div>
  </div>
)

const ModalMapSelector = ({
  props,
  latitude,
  longitude,
  setIsUpload,
  setNameEn,
  setNameTh,
  setAddressEn,
  setAddressTh,
  setLatitude,
  setLongitude,
}) => (
  <div
    id="ModalMapSelector"
    className="modal"
    style={{ paddingTop: 30, zIndex: 2000 }}
  >
    <div className="modal-content" style={{ maxWidth: '80%' }}>
      <MapSelector
        zoom={15}
        height={window.innerHeight * 0.8 + 'px'}
        locale={props.locale}
        position={{ lat: latitude, lng: longitude }}
        setIsUpload={setIsUpload}
        onClose={() => ModalHandler.closeModal('ModalMapSelector')}
        onSubmit={(name, address_en, address_th, lat, lng) => {
          ModalHandler.closeModal('ModalMapSelector')
          setNameEn(name)
          setNameTh(name)
          setAddressEn(address_en)
          setAddressTh(address_th)
          setLatitude(lat)
          setLongitude(lng)
        }}
      />
    </div>
  </div>
)

const onClickMapSelector = () => () => {
  ModalHandler.openModal('ModalMapSelector')
}

const onSubmit = (
  props,
  name_en,
  name_th,
  address_en,
  address_th,
  picture,
  latitude,
  longitude
) => () => {
  const payload = {
    ...props.data,
  }
  payload.place.name_en = name_en
  payload.place.name_th = name_th
  payload.place.address_en = address_en
  payload.place.address_th = address_th
  payload.place.picture = picture
  payload.place.latitude = parseFloat(latitude)
  payload.place.longitude = parseFloat(longitude)
  props.onSubmit(payload)
}
const onDeleteImage = (props, setPicture) => () => {
  setPicture('')
  props.onDeleteDetailImagePlace(props.data.id)
}

function handle(props) {
  const [isUpload, setIsUpload] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [name_en, setNameEn] = useState('')
  const [name_th, setNameTh] = useState('')
  const [address_en, setAddressEn] = useState('')
  const [address_th, setAddressTh] = useState('')
  const [picture, setPicture] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [image, setImage] = useState('')

  useEffect(function() {
    setDefault()
    setIsLoading(false)
  }, [])

  function setDefault() {
    setNameEn(props.data.place.name_en)
    setNameTh(props.data.place.name_th)
    setAddressEn(props.data.place.address_en)
    setAddressTh(props.data.place.address_th)
    setPicture(props.data.place.picture)
    setLatitude(props.data.place.latitude)
    setLongitude(props.data.place.longitude)
    setImage('')
  }

  function onClose() {
    setDefault()
    props.onClose()
  }

  return {
    isUpload,
    isLoading,
    name_en,
    name_th,
    address_en,
    address_th,
    picture,
    latitude,
    longitude,
    setNameEn,
    setNameTh,
    image,
    setAddressEn,
    setAddressTh,
    setLatitude,
    setLongitude,
    setIsUpload,
    setPicture,
    onClose,
    setImage,
  }
}

/**
 * @return {null}
 */
function EventDetailEditPlace(props) {
  const {
    isUpload,
    isLoading,
    name_en,
    name_th,
    address_en,
    address_th,
    picture,
    latitude,
    longitude,
    image,
    setNameEn,
    setNameTh,
    setAddressEn,
    setAddressTh,
    setLatitude,
    setLongitude,
    setIsUpload,
    setPicture,
    onClose,
    setImage,
  } = handle(props)

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="profile-box">
      <div className="profile-box__header">
        <h3>{props.locale.EDIT_PLACE}</h3>
        <div className="close-box">
          <span className="close" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      {isUpload && (
        <div style={styleBoxLoad}>
          <Loading />
        </div>
      )}
      <div className="cover-images-header" style={style1}>
        <img
          className="cover-img"
          style={styleImageCover}
          src={
            picture || picture.length > 0
              ? picture
              : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_PLACE + '?sz=s'
          }
          alt=""
        />
        <div className="__back-drop-gallery">
          <i
            className="fa fa-times"
            onClick={onDeleteImage(props, setPicture)}
          />
          <input
            type="file"
            style={style2}
            accept="image/*"
            id="input-cover-place"
            onChange={onUpload(setImage)}
          />
          <i className="far fa-image" style={style3} onClick={onClickUpload} />
        </div>
      </div>
      <Input.Textbox2
        title={props.locale.LABEL_INFO_TITLE_ENG}
        value={name_en}
        onChange={setNameEn}
      />
      <Input.Textbox2
        title={props.locale.LABEL_INFO_TITLE_TH}
        value={name_th}
        onChange={setNameTh}
      />
      <Input.Textarea2
        title={props.locale.LABEL_PLACE_ADDRESS_ENG}
        value={address_en}
        onChange={setAddressEn}
      />
      <Input.Textarea2
        title={props.locale.LABEL_PLACE_ADDRESS_TH}
        value={address_th}
        onChange={setAddressTh}
      />
      <div style={style4}>
        <div style={style5}>
          <Button.Button2 onClick={onClickMapSelector()} style={{ padding: 0 }}>
            {props.locale.SELECT_PLACE_ON_MAP}
          </Button.Button2>
          {latitude != 0 && longitude != 0 && (
            <p>
              ({latitude}, {longitude})
            </p>
          )}
        </div>
      </div>
      <div style={style4}>
        <div style={style5}>
          <Button.Button4
            onClick={onSubmit(
              props,
              name_en,
              name_th,
              address_en,
              address_th,
              picture,
              latitude,
              longitude
            )}
          >
            {props.locale.SAVE}
          </Button.Button4>
        </div>
      </div>

      <ModalPlaceCropper
        props={props}
        image={image}
        setIsUpload={setIsUpload}
        setPicture={setPicture}
      />

      <ModalMapSelector
        props={props}
        latitude={latitude}
        longitude={longitude}
        setIsUpload={setIsUpload}
        setNameEn={setNameEn}
        setNameTh={setNameTh}
        setAddressEn={setAddressEn}
        setAddressTh={setAddressTh}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
      />
    </div>
  )
}

export default EventDetailEditPlace
