import React, { Component } from 'react'
import Cropper from 'react-cropper'
import Button from '../form/buttons'
import 'cropperjs/dist/cropper.css'

class ImageCropper extends Component {
  constructor(props) {
    super()

    this.state = {
      dataUrl: null,
    }
  }

  handleCrop = () => {
    let dataUrl = this.refs.cropper.getCroppedCanvas().toDataURL()
    if (this.state.dataUrl != dataUrl) {
      this.setState({
        dataUrl: dataUrl,
      })
    }
  }

  render() {
    return (
      <div className="profile-box">
        <div className="profile-box__header">
          <h3>{this.props.locale.IMAGE_CROPPER}</h3>
          <div className="close-box">
            <span className="close" onClick={this.onClose}>
              &times;
            </span>
          </div>
        </div>
        <Cropper
          ref="cropper"
          src={this.props.image || ''}
          style={{ height: '100%', width: '100%', marginBottom: 25 }}
          aspectRatio={16 / 9}
          guides={true}
          viewMode={1}
          dragMode="move"
          crop={this.handleCrop}
        />

        <div style={{ textAlign: 'center', padding: 10 }}>
          <Button.Button4
            onClick={() => this.props.onSubmit(this.state.dataUrl)}
          >
            {this.props.locale.SAVE}
          </Button.Button4>
        </div>
      </div>
    )
  }
}

export default ImageCropper
