import React, { useState } from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import s from 'styled-components'
import swal from 'sweetalert'

import ModalHandler from '../../utils/modal'
import { uploadEventImageAPI } from '../../utils/api'
import { getShowDate, getBase64 } from '../../utils/string'
import { getLanguage } from '../../locale'

import NoPreview from '../../images/img_not_available.png'
import Loading from '../loading'
import Carousel from '../carousel'
import ImageFullScreen from '../image-fullscreen/image-fullscreen'
import EventDetailEditInfo from './event-detail-edit-info'
import EventDetailEditPlace from './event-detail-edit-place'
import Button from '../form/buttons'
import { BoxDetail, BoxDetailGallery } from '../box'
import { CarouselGalleryForEdit } from '../gallery'

const LayoutPlace = s.div`
  padding: 20px 8px;
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  
  grid-template-columns: 60% 40%;
  grid-column-gap: 20px;
  
  & > div{
    padding: 10px;
  }
  
  @media (max-width: 750px) {
  padding: 0;
    grid-template-columns: 100%;
    grid-column-gap: 0;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0;
  }
`

const styleBoxLoad = { position: 'relative', height: '80px' }
const style1 = { padding: '10px', marginTop: 20 }
const style2 = { padding: '10px', marginBottom: 20 }
const style3 = { width: 35 }
const style4 = { paddingBottom: 0 }
const style5 = { top: 0 }
const style6 = { display: 'none' }
const style7 = { background: 'none' }
const style9 = { paddingLeft: 0 }
const style10 = { padding: '30px 0 20px 0' }
const style11 = { paddingLeft: 0 }
const style12 = { paddingTop: 30 }
const style13 = { width: '100%' }
const style14 = { paddingTop: 20, color: '#776D6D' }
const ggMapStyle1 = { height: `100%` }
const ggMapStyle2 = { height: `400px` }
const ggMapStyle3 = { width: 60, height: 60 }
const styleImageCover = { width: '100%' }
const styleFont1 = { fontFamily: `'Noto Serif', serif` }

const defaultOptions = {
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
}

const GGMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      defaultOptions={defaultOptions}
      disableDefaultUI
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: props.lat, lng: props.lng }}
          icon={props.icon}
        />
      )}
    </GoogleMap>
  ))
)

const ModalDetailInfo = ({
  data,
  logo_img,
  cover_img,
  onSubmit,
  onAddLogoImage,
  onChangeImageCover,
  onDeleteDetailImageCover,
  locale,
}) => (
  <div
    id="modalDetailInfo"
    className="modal"
    style={{ paddingTop: 30, zIndex: 1180 }}
  >
    <div className="modal-content" style={{ maxWidth: 550 }}>
      <EventDetailEditInfo
        locale={locale}
        data={data}
        logo_img={logo_img}
        cover_img={cover_img}
        onSubmit={payload => {
          onSubmit(payload)
          ModalHandler.closeModal('modalDetailInfo')
        }}
        onAddLogoImage={onAddLogoImage}
        onChangeImageCover={onChangeImageCover}
        onDeleteDetailImageCover={onDeleteDetailImageCover}
        onClose={() => ModalHandler.closeModal('modalDetailInfo')}
      />
    </div>
  </div>
)

const ModalDetailPlace = ({
  data,
  onSubmit,
  onDeleteDetailImagePlace,
  locale,
}) => (
  <div
    id="modalDetailPlace"
    className="modal"
    style={{ paddingTop: 30, zIndex: 1000 }}
  >
    <div className="modal-content" style={{ maxWidth: 550 }}>
      <EventDetailEditPlace
        locale={locale}
        data={data}
        onSubmit={payload => {
          onSubmit(payload)
          ModalHandler.closeModal('modalDetailPlace')
        }}
        onDeleteDetailImagePlace={onDeleteDetailImagePlace}
        onClose={() => ModalHandler.closeModal('modalDetailPlace')}
      />
    </div>
  </div>
)

function goLing(link) {
  window.location = link
}

const onUploadGallery = (props, setIsUpload) => async e => {
  if (e.currentTarget.files[0]) {
    const data = await getBase64(e.currentTarget.files[0])
    setIsUpload(true)
    const res = await uploadEventImageAPI(props.dataOriginal.id, {
      data_url: data,
    })
    if (res.code === 'OK') {
      props.onAddPhotoGallery(data)
      setIsUpload(false)
    } else {
      await swal(props.locale.ERROR, props.locale.WARNING_UPLOAD_FAIL, 'error')
      setIsUpload(false)
    }
  }
}

const onClickAddPhotoGallery = () =>
  document.getElementById('input-add-photo-gallery').click()
const onClickGoLing = url => () => goLing(url)
const onClickOpenModalInfo = () => {
  ModalHandler.openModal('modalDetailInfo')
}
const onClickImageToFullScreen = (setIndexImage, setonShowImage) => e => {
  setIndexImage(parseInt(e.currentTarget.getAttribute('data-index')))
  setonShowImage(true)
}
const onCloseImageFullScreen = setonShowImage => () => setonShowImage(false)
const onClickOpenModalPlace = () => ModalHandler.openModal('modalDetailPlace')

function handleImageFullScreen() {
  const [isUpload, setIsUpload] = useState(false)
  const [indexImage, setIndexImage] = useState(0)
  const [isShowImage, setonShowImage] = useState(false)

  return {
    isUpload,
    indexImage,
    isShowImage,
    setIsUpload,
    setIndexImage,
    setonShowImage,
  }
}

export default props => {
  const lang = getLanguage().lang

  const {
    isUpload,
    indexImage,
    isShowImage,
    setIsUpload,
    setIndexImage,
    setonShowImage,
  } = handleImageFullScreen()

  let description =
    lang === 'TH'
      ? props.dataOriginal.description_th
      : props.dataOriginal.description_en
  let descriptions = description.split(/\r\n|\r|\n/)
  let descriptionRow = descriptions.length > 4 ? descriptions.length : 4

  descriptions.map(data => {
    descriptionRow += Math.floor(data.length / (window.innerWidth * 0.08))
  })

  return (
    <div className="event-detail-section">
      <div className="cover-images-header">
        <img
          className="cover-img"
          id="imageCoverMain"
          style={styleImageCover}
          src={
            props.data.cover_img || props.data.cover_img.length > 0
              ? props.data.cover_img
              : NoPreview
          }
          alt=""
        />
        <img
          className="cover-logo-img"
          src={
            props.data.logo_img || props.data.logo_img.length > 0
              ? props.data.logo_img
              : NoPreview
          }
          alt=""
        />
      </div>
      <BoxDetail>
        <div className="event-detail-body-content">
          <div>
            <h3>{props.dataOriginal.title}</h3>
            <small>{getShowDate(lang, props.dataOriginal.date)}</small>
            <textarea
              disabled={true}
              style={{
                display: 'block',
                width: '100%',
                resize: 'none',
                height: 'auto',
                outline: 'none',
              }}
              rows={descriptionRow}
              value={description}
            />

            {props.dataOriginal.website ||
            props.dataOriginal.website.length > 0 ? (
              <div style={style1} className="__row-link-share">
                <Button.Button3 icon="fas fa-globe-asia" />{' '}
                <span onClick={onClickGoLing(props.dataOriginal.website)}>
                  {props.dataOriginal.website}
                </span>
              </div>
            ) : null}
            {props.dataOriginal.facebook_link ||
            props.dataOriginal.facebook_link.length > 0 ? (
              <div style={style2} className="__row-link-share">
                <Button.Button3 icon="fab fa-facebook-f" style={style3} />{' '}
                <span onClick={onClickGoLing(props.dataOriginal.facebook_link)}>
                  {props.dataOriginal.facebook_link}
                </span>
              </div>
            ) : null}
          </div>
          <div className="__box-edit_info">
            <Button.Button3 icon="fa fa-pen" onClick={onClickOpenModalInfo} />
          </div>
        </div>
      </BoxDetail>
      <BoxDetailGallery>
        <div className="event-detail-body-gallery" style={style4}>
          <div className="card__header">
            <h3 style={styleFont1}>{props.locale.PHOTO_GALLERY}</h3>
            <div className="underline bred" />
            <div className="__box-edit_gallery" style={style5}>
              <input
                type="file"
                style={style6}
                accept="image/*"
                id="input-add-photo-gallery"
                onChange={onUploadGallery(props, setIsUpload)}
              />
              <Button.Button3
                icon="fa fa-plus"
                style={style7}
                onClick={onClickAddPhotoGallery}
              />
            </div>
          </div>
          <div className="photo-gallery__box" style={{ paddingLeft: 10 }}>
            {isUpload && (
              <div style={styleBoxLoad}>
                <Loading />
              </div>
            )}
            {props.data.photo_gallery_img.length > 0 && (
              <Carousel showEveryTime>
                <CarouselGalleryForEdit
                  data={props.data.photo_gallery_img}
                  dataOriginal={props.dataOriginal}
                  setIndexImage={setIndexImage}
                  setonShowImage={setonShowImage}
                  onClickImageToFullScreen={onClickImageToFullScreen}
                  onDeletePhotoGallery={props.onDeletePhotoGallery}
                />
              </Carousel>
            )}
            <ImageFullScreen
              data={props.data.photo_gallery_img}
              index={indexImage}
              isShow={isShowImage}
              onClose={onCloseImageFullScreen(setonShowImage)}
            />
          </div>
        </div>
      </BoxDetailGallery>
      <BoxDetail>
        <LayoutPlace>
          <div>
            <div className="card__header" style={style9}>
              <h3 style={styleFont1}>{props.locale.MAP}</h3>
              <div className="underline bred" />
            </div>
            <div style={style10} id="box-google-map">
              <GGMap
                lat={parseFloat(props.dataOriginal.place.latitude)}
                lng={parseFloat(props.dataOriginal.place.longitude)}
                isMarkerShown
                googleMapURL={process.env.GATSBY_GOOGLE_MAP_URL}
                loadingElement={<div style={ggMapStyle1} />}
                containerElement={<div style={ggMapStyle2} />}
                mapElement={<div style={ggMapStyle1} />}
                icon={{ url: props.data.logo_img, scaledSize: ggMapStyle3 }}
              />
            </div>
          </div>
          <div className="__place_section">
            <div className="card__header" style={style11}>
              <h3 style={styleFont1}>{props.locale.PLACE}</h3>
              <div className="underline bred" />
              <div className="__box-edit_gallery" style={style5}>
                <Button.Button3
                  icon="fa fa-pen"
                  onClick={onClickOpenModalPlace}
                />
              </div>
            </div>
            <div style={style12}>
              <img
                src={
                  props.dataOriginal.place.picture ||
                  props.dataOriginal.place.picture.length > 0
                    ? props.dataOriginal.place.picture
                    : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_PLACE +
                      '?sz=m'
                }
                alt=""
                className="place-shadow"
                style={style13}
              />
            </div>
            <div style={style14}>
              <p>
                {lang === 'TH'
                  ? props.dataOriginal.place.address_th
                  : props.dataOriginal.place.address_en}
              </p>
            </div>
          </div>
        </LayoutPlace>
      </BoxDetail>

      <ModalDetailInfo
        data={props.dataOriginal}
        onSubmit={props.onDetailSubmit}
        logo_img={props.data.logo_img}
        cover_img={props.data.cover_img}
        onAddLogoImage={props.onAddLogoImage}
        onChangeImageCover={props.onChangeImageCover}
        onDeleteDetailImageCover={props.onDeleteDetailImageCover}
        locale={props.locale}
      />
      <ModalDetailPlace
        data={props.dataOriginal}
        onSubmit={props.onDetailSubmit}
        onDeleteDetailImagePlace={props.onDeleteDetailImagePlace}
        locale={props.locale}
      />
    </div>
  )
}
