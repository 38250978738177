import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import View from '../../components/presentation/event-detail-edit'

import authActions from '../../state/auth/actions'
import homeActions from '../../state/home/actions'

class EventDetailPage extends React.Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search)
    this.props.checkAuthorization(null, null, null, {
      onSuccess: () => this.props.onLoad(params.id),
      onError: () => this.props.onLoad(params.id),
    })
  }

  componentWillUnmount() {
    this.props.onReset()
  }

  onReload = () => this.props.onLoad(this.props.state.eventDetailOriginal.id)

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }

    if (this.props.state.eventDetailOriginal.id === 0) {
      return <Loading />
    }

    // const params = queryString.parse(this.props.location.search)
    return (
      <Layout auth={this.props.auth.currentUser !== null}>
        <SEO keywords={[`invitree`]} />
        <View
          auth={this.props.auth.currentUser !== null}
          data={this.props.state.eventDetail}
          dataOriginal={this.props.state.eventDetailOriginal}
          onDetailSubmit={this.props.onDetailSubmit}
          onChangeImageCover={this.onReload}
          onAddLogoImage={this.onReload}
          onAddPhotoGallery={this.onReload}
          onDeletePhotoGallery={this.props.onDeletePhotoGallery}
          onDeleteDetailImageCover={this.props.onDeleteDetailImageCover}
          onDeleteDetailImagePlace={this.props.onDeleteDetailImagePlace}
          locale={this.props.app.locale[this.props.app.lang]}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadDetail,
      onDetailSubmit: homeActions.onDetailSubmit,
      // onChangeImageCover: homeActions.onChangeImageCover,
      // onAddPhotoGallery: homeActions.onAddPhotoGallery,
      // onAddLogoImage: homeActions.onAddLogoImage,
      onDeletePhotoGallery: homeActions.onDeletePhotoGallery,
      onDeleteDetailImageCover: homeActions.onDeleteDetailImageCover,
      onDeleteDetailImagePlace: homeActions.onDeleteDetailImagePlace,
      onReset: homeActions.onReset,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailPage)
