import React, { useEffect, useState } from 'react'
import { getBase64, ISODateString } from '../../utils/string'
import swal from 'sweetalert'
import {
  deleteEventBannerImageAPI,
  deleteEventLogoAPI,
  uploadEventBannerImageAPI,
  uploadEventLogoAPI,
} from '../../utils/api'

import ModalHandler from '../../utils/modal'
import ImageCropper from '../image-cropper/index'
import Loading from '../loading'
import UploadLogo from '../upload/profile'
import Input from '../form/input'
import Button from '../form/buttons'
import NoPreview from '../../images/img_not_available.png'

const styleBoxLoad = { position: 'relative', height: '80px' }
const style1 = { position: 'relative' }
const style2 = { display: 'none' }
const style3 = { marginLeft: 20 }
const style4 = { textAlign: 'center', padding: 10 }
const style5 = { padding: 5 }
const styleImageCover = { width: '100%' }

const ddlPrivacy = locale => {
  return [
    { id: 'private', title: locale.PRIVATE },
    { id: 'public_view', title: locale.PUBLIC_VIEW },
    { id: 'public_join', title: locale.PUBLIC_JOIN },
    { id: 'club_join', title: locale.CLUB_JOIN },
  ]
}

const ddlJoinType = (joinType, allow_payment, locale) => {
  const arr = [{ id: 'free', title: locale.FREE }]

  if (allow_payment) {
    arr.push({ id: 'paid_or_free', title: locale.PAID_OR_FREE })
    arr.push({ id: 'paid', title: locale.PAID })
  } else if (joinType !== 'free') {
    arr.push({
      id: joinType,
      title: joinType.charAt(0).toUpperCase() + joinType.slice(1),
    })
  }

  return arr
}

const ddlStatus = locale => {
  return [
    { id: 'disabled', title: locale.DISABLED },
    { id: 'enabled', title: locale.ENABLED },
  ]
}

const onClickUploadCover = () =>
  document.getElementById('input-cover-image').click()

const onChangeUploadCover = setImage => async e => {
  if (e.currentTarget.files[0]) {
    const data = await getBase64(e.currentTarget.files[0])
    setImage(data)
    ModalHandler.openModal('ModalCoverCropper')
  }
}

const ModalCoverCropper = ({ props, image, setIsUpload }) => (
  <div
    id="ModalCoverCropper"
    className="modal"
    style={{ paddingTop: 30, zIndex: 1180 }}
  >
    <div className="modal-content" style={{ maxWidth: 550 }}>
      <ImageCropper
        locale={props.locale}
        image={image}
        onClose={() => ModalHandler.closeModal('ModalCoverCropper')}
        onSubmit={async imageUrl => {
          ModalHandler.closeModal('ModalCoverCropper')

          setIsUpload(true)
          await deleteEventBannerImageAPI(props.data.id)
          const res = await uploadEventBannerImageAPI(props.data.id, {
            data_url: imageUrl,
          })
          if (res.code === 'OK') {
            props.onChangeImageCover(imageUrl)
            await swal(
              props.locale.SUCCESS,
              props.locale.WARNING_UPLOAD_SUCCESS,
              'success'
            )
            setIsUpload(false)
          } else {
            await swal(
              props.locale.ERROR,
              props.locale.WARNING_UPLOAD_FAIL,
              'error'
            )
            setIsUpload(false)
          }
        }}
      />
    </div>
  </div>
)

const onChangeUploadLogo = setImage => async e => {
  if (e.currentTarget.files[0]) {
    const data = await getBase64(e.currentTarget.files[0])
    setImage(data)
    ModalHandler.openModal('ModalLogoCropper')
  }
}

const ModalLogoCropper = ({ props, image, setIsUpload }) => (
  <div
    id="ModalLogoCropper"
    className="modal"
    style={{ paddingTop: 30, zIndex: 1180 }}
  >
    <div className="modal-content" style={{ maxWidth: 550 }}>
      <ImageCropper
        locale={props.locale}
        image={image}
        onClose={() => ModalHandler.closeModal('ModalLogoCropper')}
        onSubmit={async imageUrl => {
          ModalHandler.closeModal('ModalLogoCropper')

          setIsUpload(true)
          await deleteEventLogoAPI(props.data.id)
          const res = await uploadEventLogoAPI(props.data.id, {
            data_url: imageUrl,
          })
          if (res.code === 'OK') {
            props.onAddLogoImage(imageUrl)
            await swal(
              props.locale.SUCCESS,
              props.locale.WARNING_UPLOAD_SUCCESS,
              'success'
            )
            setIsUpload(false)
          } else {
            await swal(
              props.locale.ERROR,
              props.locale.WARNING_UPLOAD_FAIL,
              'error'
            )
            setIsUpload(false)
          }
        }}
      />
    </div>
  </div>
)

const onSubmit = (
  props,
  title_en,
  title_th,
  description_en,
  description_th,
  date,
  last_payment_date,
  website,
  facebook_link,
  join_type,
  privacy,
  status
) => () =>
  props.onSubmit({
    ...props.data,
    title_en,
    title_th,
    description_en,
    description_th,
    date,
    last_payment_date,
    website,
    facebook_link,
    join_type,
    privacy,
    status,
  })

function handle(props) {
  const [isUpload, setIsUpload] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [title_en, setTitleEn] = useState('')
  const [title_th, setTitleTh] = useState('')
  const [description_en, setDescriptionEn] = useState('')
  const [description_th, setDescriptionTh] = useState('')
  const [date, setDate] = useState('')
  const [website, setWebsite] = useState('')
  const [facebook_link, setFacebook] = useState('')
  const [joinType, setJoinType] = useState('free')
  const [privacy, setPrivacy] = useState('private')
  const [status, setStatus] = useState('disabled')
  const [last_payment_date, setLastPaymentDate] = useState('')
  const [logoImage, setLogoImage] = useState('')
  const [coverImage, setCoverImage] = useState('')

  useEffect(function() {
    setDefault()
    setIsLoading(false)
  }, [])

  function setDefault() {
    setTitleEn(props.data.title_en)
    setTitleTh(props.data.title_th)
    setDescriptionEn(props.data.description_en)
    setDescriptionTh(props.data.description_th)
    setDate(props.data.date)
    setWebsite(props.data.website || '')
    setFacebook(props.data.facebook_link || '')
    setJoinType(props.data.join_type)
    setPrivacy(props.data.privacy)
    setStatus(props.data.status)
    setLastPaymentDate(
      props.data.last_payment_date || ISODateString(new Date())
    )
    setLogoImage('')
    setCoverImage('')
  }

  function onClose() {
    setDefault()
    props.onClose()
  }

  return {
    isUpload,
    isLoading,
    title_en,
    title_th,
    description_en,
    description_th,
    date,
    website,
    facebook_link,
    joinType,
    privacy,
    status,
    last_payment_date,
    logoImage,
    coverImage,
    setTitleEn,
    setTitleTh,
    setDescriptionEn,
    setDescriptionTh,
    setDate,
    setLastPaymentDate,
    setWebsite,
    setFacebook,
    setJoinType,
    setPrivacy,
    setStatus,
    setIsUpload,
    onClose,
    setLogoImage,
    setCoverImage,
  }
}

/**
 * @return {null}
 */
function EventDetailEditInfo(props) {
  const {
    isUpload,
    isLoading,
    title_en,
    title_th,
    description_en,
    description_th,
    date,
    website,
    facebook_link,
    joinType,
    privacy,
    status,
    last_payment_date,
    logoImage,
    coverImage,
    setTitleEn,
    setTitleTh,
    setDescriptionEn,
    setDescriptionTh,
    setDate,
    setLastPaymentDate,
    setWebsite,
    setFacebook,
    setJoinType,
    setPrivacy,
    setStatus,
    setIsUpload,
    onClose,
    setLogoImage,
    setCoverImage,
  } = handle(props)

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="profile-box">
      <div className="profile-box__header">
        <h3>{props.locale.EDIT_INFO}</h3>
        <div className="close-box">
          <span className="close" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      {isUpload && (
        <div style={styleBoxLoad}>
          <Loading />
        </div>
      )}
      <div className="cover-images-header" style={style1}>
        <img
          className="cover-img"
          id="modalDetailInfoImageCover"
          style={styleImageCover}
          src={
            props.cover_img || props.cover_img.length > 0
              ? props.cover_img
              : NoPreview
          }
          alt=""
        />
        <div className="__back-drop-gallery">
          <i
            className="fa fa-times"
            onClick={() => props.onDeleteDetailImageCover(props.data.id)}
          />
          <input
            type="file"
            style={style2}
            accept="image/*"
            id="input-cover-image"
            onChange={onChangeUploadCover(setCoverImage)}
          />
          <i
            className="far fa-image"
            style={style3}
            onClick={onClickUploadCover}
          />
        </div>
      </div>
      <UploadLogo
        id={'input-add-photo-logo'}
        value={props.logo_img}
        onChange={onChangeUploadLogo(setLogoImage)}
      />
      <Input.Textbox2
        title={props.locale.LABEL_INFO_TITLE_ENG}
        value={title_en}
        onChange={setTitleEn}
      />
      <Input.Textbox2
        title={props.locale.LABEL_INFO_TITLE_TH}
        value={title_th}
        onChange={setTitleTh}
      />
      <Input.Textarea2
        title={props.locale.LABEL_INFO_DESC_ENG}
        value={description_en}
        onChange={setDescriptionEn}
      />
      <Input.Textarea2
        title={props.locale.LABEL_INFO_DESC_TH}
        value={description_th}
        onChange={setDescriptionTh}
      />
      <Input.DateTimePicker
        title={props.locale.DATE}
        defaultValue={date}
        onChange={setDate}
      />
      <Input.DateTimePicker
        title={props.locale.LAST_PAYMENT_DATE}
        defaultValue={last_payment_date}
        onChange={setLastPaymentDate}
      />
      <Input.Textbox2
        title={props.locale.WEBSITE}
        value={website}
        onChange={setWebsite}
      />
      <Input.Textbox2
        title={props.locale.FACEBOOK}
        value={facebook_link}
        onChange={setFacebook}
      />
      {status === 'enabled' || status === 'disabled' ? (
        <Input.Dropdown2
          title={props.locale.STATUS}
          value={status}
          data={ddlStatus(props.locale)}
          onChange={setStatus}
        />
      ) : (
        <Input.Dropdown2
          title={props.locale.STATUS}
          value={status}
          data={[
            ...ddlStatus(props.locale),
            {
              id: 'banned',
              title: props.locale.BANNED,
            },
            { id: 'closed', title: props.locale.CLOSED },
          ]}
          disabled
        />
      )}
      <Input.Dropdown2
        title={props.locale.JOIN_TYPE}
        value={joinType}
        data={ddlJoinType(joinType, props.data.allow_payment, props.locale)}
        onChange={setJoinType}
      />
      <Input.Dropdown2
        title={props.locale.PRIVACY}
        value={privacy}
        data={ddlPrivacy(props.locale)}
        onChange={setPrivacy}
      />
      <div style={style4}>
        <div style={style5}>
          <Button.Button4
            onClick={onSubmit(
              props,
              title_en,
              title_th,
              description_en,
              description_th,
              date,
              last_payment_date,
              website,
              facebook_link,
              joinType,
              privacy,
              status
            )}
          >
            {props.locale.SAVE}
          </Button.Button4>
        </div>
      </div>
      <ModalLogoCropper
        props={props}
        image={logoImage}
        setIsUpload={setIsUpload}
      />
      <ModalCoverCropper
        props={props}
        image={coverImage}
        setIsUpload={setIsUpload}
      />
    </div>
  )
}

export default EventDetailEditInfo
