async function handleUrl(url) {
  const response = await fetch(url).catch(() =>
    Promise.reject(new Error('Error fetching data'))
  )

  const json = await response.json().catch(() => {
    return Promise.reject(new Error('Error parsing server response'))
  })

  if (json.status === 'OK') {
    return json
  }

  return Promise.reject(
    new Error(
      `${json.error_message}.\nServer returned status code ${json.status}`
    )
  )
}

export default {
  async fromLatLng(lat, lng, language = 'en') {
    if (!lat || !lng) {
      return Promise.reject(new Error('Provided coordinates are invalid'))
    }
    let latLng = `${lat},${lng}`

    let url = 'https://maps.google.com/maps/api/geocode/json'
    url += `?latlng=${encodeURIComponent(latLng)}`
    url += `&key=${process.env.GATSBY_GOOGLE_MAP_KEY}`
    url += `&language=${language}`

    return handleUrl(url)
  },
}
