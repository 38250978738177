import React from 'react'
import s from 'styled-components'

const BoxImage = s.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  @media (max-width: 750px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
`

const styleImage = { minHeight: 150 }
const style1 = {
  paddingBottom: 20,
  paddingLeft: 5,
  paddingTop: 5,
  paddingRight: 6,
}
export const CarouselGallery = props => {
  let _indexImage = -1

  return (
    <React.Fragment>
      {props.data.map((page, page_index) => (
        <div key={`photo-page-${page_index}`} style={style1}>
          {page.map((pageRow, pageRowIndex) => (
            <BoxImage key={`photo-page-${page_index}-row-${pageRowIndex}`}>
              {pageRow.map((item, index) => {
                _indexImage++
                return (
                  <img
                    key={`photo-items-${_indexImage}`}
                    className="photo-gallery__image"
                    src={item.img_url + '?sz=l'}
                    style={styleImage}
                    alt=""
                    data-index={_indexImage}
                    onClick={props.onClickImageToFullScreen}
                  />
                )
              })}
            </BoxImage>
          ))}
        </div>
      ))}
    </React.Fragment>
  )
}

export const CarouselGalleryForEdit = props => {
  let _indexImage = -1

  return (
    <React.Fragment>
      {props.data.map((page, page_index) => (
        <div key={`photo-page-${page_index}`} style={style1}>
          {page.map((pageRow, pageRowIndex) => (
            <BoxImage key={`photo-page-${page_index}-row-${pageRowIndex}`}>
              {pageRow.map((item, index) => {
                _indexImage++
                return (
                  <div
                    key={`photo-items-${_indexImage}`}
                    className="__image-row-gallery"
                  >
                    <img
                      className="photo-gallery__image"
                      src={
                        item.img_url.search('https://') > -1
                          ? item.img_url + '?sz=s'
                          : item.img_url
                      }
                      style={styleImage}
                      alt=""
                      data-index={_indexImage}
                      onClick={props.onClickImageToFullScreen(
                        props.setIndexImage,
                        props.setonShowImage
                      )}
                    />
                    <div
                      className="__back-drop-gallery"
                      onClick={() =>
                        props.onDeletePhotoGallery(
                          page_index,
                          pageRowIndex,
                          _indexImage,
                          props.dataOriginal.id,
                          item.img_url
                        )
                      }
                    >
                      <i className="fa fa-times" />
                    </div>
                  </div>
                )
              })}
            </BoxImage>
          ))}
        </div>
      ))}
    </React.Fragment>
  )
}
