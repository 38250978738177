import React, { Component } from 'react'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from 'react-google-maps'
import Geocode from './react-geocode'

import { getLanguage } from '../../locale'
import Button from '../form/buttons'

const {
  SearchBox,
} = require('react-google-maps/lib/components/places/SearchBox')

class MapSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      refs: {},
      placeName: '',
      address_en: '',
      address_th: '',
      position: this.props.position,
    }
  }

  componentDidMount() {
    let { lat, lng } = this.state.position
    if (lat == 0 && lng == 0) {
      this.props.setIsUpload(true)
      navigator.geolocation.getCurrentPosition(position => {
        lat = position.coords.latitude
        lng = position.coords.longitude
        this.setState({
          position: {
            lat: lat,
            lng: lng,
          },
        })

        this.getGeocode(lat, lng)
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.position.lat !== this.props.position.lat ||
      this.state.address_en !== nextState.address_en ||
      this.state.address_th !== nextState.address_th
    ) {
      return true
    } else if (this.props.position.lat === nextProps.position.lat) {
      return false
    }
  }

  getGeocode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng, 'en').then(
      response => {
        const address_en = response.results[0].formatted_address
        this.setState({
          address_en: address_en ? address_en : '',
          position: {
            lat: lat,
            lng: lng,
          },
        })
        this.props.setIsUpload(false)
      },
      error => {
        console.error('error', error)
        this.props.setIsUpload(false)
      }
    )

    Geocode.fromLatLng(lat, lng, 'th').then(response => {
      const address_th = response.results[0].formatted_address
      this.setState({
        address_th: address_th ? address_th : '',
      })
    })
  }

  onMarkerDragEnd = event => {
    this.getGeocode(event.latLng.lat(), event.latLng.lng())
  }

  onPlaceSelected = place => {
    if (place.formatted_address) {
      this.getGeocode(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      )
    }
  }

  onClickSubmit = () => {
    this.props.onSubmit(
      this.state.placeName,
      this.state.address_en,
      this.state.address_th,
      this.state.position.lat,
      this.state.position.lng
    )
  }

  onSearchBoxMounted = ref => {
    this.state.refs.searchBox = ref
  }

  onPlacesChanged = () => {
    let places = this.state.refs.searchBox.getPlaces()
    let { geometry, name } = places[0]

    this.setState({ placeName: name })
    this.getGeocode(geometry.location.lat(), geometry.location.lng())
  }

  render() {
    let { address_en, address_th } = this.state
    let headerInfo = this.props.locale.SELECT
    if (this.state.placeName != '') {
      headerInfo += '  "' + this.state.placeName + '"'
    }

    const AsyncMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.position.lat + 0.005,
            lng: this.state.position.lng,
          }}
        >
          <SearchBox
            ref={this.onSearchBoxMounted}
            controlPosition={window.google.maps.ControlPosition.TOP_CENTER}
            onPlacesChanged={this.onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Enter a location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `50%`,
                height: `32px`,
                marginTop: `50px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
              }}
            />
          </SearchBox>
          {(address_en != '' || address_th) && (
            <InfoWindow
              position={{
                lat: this.state.position.lat + 0.0018,
                lng: this.state.position.lng,
              }}
            >
              <div>
                <Button.Button2
                  onClick={this.onClickSubmit}
                  style={{ color: '#5c5c5c' }}
                >
                  <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
                    {headerInfo}
                  </span>
                  <br />
                  <span>
                    {getLanguage().lang === 'TH' ? address_th : address_en}
                  </span>
                </Button.Button2>
              </div>
            </InfoWindow>
          )}
          {this.state.position && (
            <Marker
              draggable={true}
              position={this.state.position}
              onDragEnd={this.onMarkerDragEnd}
            />
          )}
        </GoogleMap>
      ))
    )

    return (
      <div className="profile-box">
        <div className="profile-box__header">
          <h3>{this.props.locale.SELECT_PLACE_ON_MAP}</h3>
          <div className="close-box">
            <span className="close" onClick={this.props.onClose}>
              &times;
            </span>
          </div>
        </div>
        <AsyncMap
          googleMapURL={process.env.GATSBY_GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: this.props.height }} />}
          containerElement={<div style={{ height: this.props.height }} />}
          mapElement={<div style={{ height: this.props.height }} />}
        />
      </div>
    )
  }
}

export default MapSelector
