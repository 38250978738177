import React, { useState, useEffect } from 'react'

function converDataToArray(data) {
  const result = []
  if (data.length > 0) {
    data.forEach(v => {
      if (v.length > 0) {
        v.forEach(v2 => {
          if (v2.length > 0) {
            v2.forEach(v3 => {
              result.push(v3.img_url)
            })
          }
        })
      }
    })
  }
  return result
}

function handle(props) {
  const [datas] = useState(converDataToArray(props.data || []))
  const [index, setIndex] = useState(0)

  useEffect(
    function() {
      setIndex(props.index)
    },
    [props.index]
  )

  function handlePrev() {
    const v = index - 1
    if (v < 0) {
      return false
    }
    setIndex(v)
  }

  function handleNext() {
    const v = index + 1
    if (v > datas.length - 1) {
      return false
    }
    setIndex(v)
  }

  return {
    datas,
    index,
    handlePrev,
    handleNext,
  }
}

/**
 * @return {null}
 */
export default function ImageFullScreen(props) {
  const { datas, index, handlePrev, handleNext } = handle(props)

  if (!props.isShow) {
    return null
  }

  return (
    <div className="home lg-on loaded in">
      <div className="lg-backdrop in" style={{ transitionDuration: '150ms' }} />
      <div className="lg-outer lg-start-zoom lg-use-css3 lg-css3 lg-slide lg-show-after-load lg-use-transition-for-zoom lg-visible lg-grab">
        <div className="lg" style={{ width: '100%', height: '100%' }}>
          <div className="lg-toolbar group">
            <span className="lg-close lg-icon" onClick={props.onClose} />
          </div>
          <div
            className="lg-inner"
            style={{
              transitionTimingFunction: 'cubic-bezier(0.25, 0, 0.25, 1)',
              transitionDuration: '400ms',
            }}
          >
            <div className="lg-loaded lg-complete lg-zoomable">
              <div className="lg-img-wrap">
                <img className="lg-image" src={datas[index]} alt="" />
              </div>
            </div>
          </div>

          <div className="lg-actions">
            <div className="lg-prev lg-icon" onClick={handlePrev} />
            <div className="lg-next lg-icon" onClick={handleNext} />
          </div>
          <div id="lg-counter">
            <span id="lg-counter-current">{index + 1}</span> /{' '}
            <span id="lg-counter-all">{datas.length}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
